import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import {getImage, StaticImage} from "gatsby-plugin-image";
import {convertToBgImage} from "gbimage-bridge";
import Header from "../components/header";
import ActionButton from "../components/action-button";
import BackgroundImage from "gatsby-background-image";
import Footer from "../components/footer";

export default function ServicesPage() {

    const {blueBg, yellowBg} = useStaticQuery(
        graphql`
        query {
            blueBg: file(relativePath: { eq: "cyan-blue.png" }) {
                childImageSharp {
                    gatsbyImageData(
                      breakpoints: [2500]
                      quality: 100
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
            }
            yellowBg: file(relativePath: { eq: "cyan-yellow.png" }) {
                childImageSharp {
                    gatsbyImageData(
                      breakpoints: [2500]
                      quality: 100
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
            }
        }
    `
    );

    const bgBlueImage = convertToBgImage(getImage(blueBg));
    const bgYellowImage = convertToBgImage(getImage(yellowBg));

    return (
        <main className="w-full">
            <BackgroundImage Tag="section" className="relative min-h-screen lg:min-h-3-4-screen w-full" {...bgBlueImage}>
                <div className="absolute w-full">
                    <Header/>
                </div>
                <div className="max-w-7xl mx-auto flex flex-col justify-center items-center min-h-screen lg:min-h-3-4-screen py-24">
                    <div className="h-28 lg:hidden" />
                    <div
                        className="px-8 lg:px-10 py-5 leading-tight font-poppins text-white text-center text-3xl lg:text-5xl lg:max-w-4xl">¡Recluta
                        con nosotros!
                    </div>
                    <div className="px-10 py-5 leading-loose font-poppins text-white text-center text-md lg:text-xl lg:max-w-4xl">
                        ¿Buscas contratar a un equipo de ensueño? ¿O tal vez sólo necesitas encontrar a ese candidato
                        que sobresale en habilidades específicas?
                    </div>
                    <div
                        className="px-10 py-10 grid grid-cols-1 lg:grid-cols-3 gap-10 text-white font-poppins items-center justify-center text-center">
                        <div>
                            <div className="text-3xl lg:text-4xl my-8">RECLUTAMIENTO</div>
                            <div>Establecemos procesos de selección a medida, donde nuestros headhunters te acompañan
                                durante todo el proceso.
                            </div>
                        </div>
                        <div>
                            <div className="text-3xl lg:text-4xl my-8">SELECCIÓN</div>
                            <div>Accede a profesionales cualificados. Nuestras áreas de especialización son la
                                programación, el diseño y el marketing digital.
                            </div>
                        </div>
                        <div>
                            <div className="text-3xl lg:text-4xl my-8">SEGUIMIENTO</div>
                            <div>Sigue de cerca el desarrollo del talento durante los siguientes meses, de la mano de
                                nuestro equipo de recursos humanos.
                            </div>
                        </div>
                    </div>
                </div>
            </BackgroundImage>
            <section className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-3 gap-10 p-10 pb-14 justify-center items-center font-poppins text-center">
                <div>
                    <StaticImage imgClassName="rounded-full" className="w-40 my-8" src="../images/chat.png" alt="Consultorías"/>
                    <div className="text-xl lg:text-2xl">
                        +860 Consultorías
                    </div>
                </div>
                <div>
                    <StaticImage imgClassName="rounded-full" className="w-40 my-8" src="../images/picture.png" alt="Consultorías"/>
                    <div className="text-xl lg:text-2xl">
                        +740 Ofertas publicadas
                    </div>
                </div>
                <div>
                    <StaticImage imgClassName="rounded-full" className="w-40 my-8" src="../images/pin.png" alt="Consultorías"/>
                    <div className="text-xl lg:text-2xl">
                        +10.250 Profesionales IT
                    </div>
                </div>
            </section>
            <BackgroundImage Tag="section" className="min-h-3-4-screen w-full" {...bgYellowImage}>
                <div className="py-14 lg:pt-32 lg:pb-20 px-8 font-poppins leading-tight text-white text-center text-3xl lg:text-4xl">Estamos siempre en la
                    búsqueda de:
                </div>
                <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-3 gap-20 p-10 font-poppins text-white">
                    <div>
                        <div className="mb-5 font-bold text-xl">Software Developers</div>
                        <div>Desarrolladores frontend, backend, mobile, QA Automation, QA Engineer, y QA Tester.</div>
                    </div>
                    <div>
                        <div className="mb-5 font-bold text-xl">Product Talent</div>
                        <div>Product owners y Project Manager que tengan gran visión y capacidad de liderazgo de
                            producto.
                        </div>
                    </div>
                    <div>
                        <div className="mb-5 font-bold text-xl">Engineering Leaders</div>
                        <div>Software Architects, technical leads y otros líderes de ingeniería de alto nivel.</div>
                    </div>
                    <div>
                        <div className="mb-5 font-bold text-xl">Data Experts</div>
                        <div>Data scientists, data engineers, machine learning y AI.</div>
                    </div>
                    <div>
                        <div className="mb-5 font-bold text-xl">Marketing y Ventas</div>
                        <div>CMO/Chief Digital Officer, Sales Manager/Account Manager, SEO/PPC Specialist, E-Commerce
                            Manager.
                        </div>
                    </div>
                    <div>
                        <div className="mb-5 font-bold text-xl">Diseño</div>
                        <div>UX Designer, UX Researcher, UI Designer</div>
                    </div>
                </div>
            </BackgroundImage>
            <section className="min-h-3-4-screen w-full flex flex-col justify-center text-center p-8 lg:p-20 font-poppins">
                <div className="text-5xl lg:text-6xl my-10">¡Únete!</div>
                <div>Somos los headhunters personales para tus búsquedas de talento.</div>
                <div className="px-10 my-16"><ActionButton to="/lets-start">¡Comencemos ahora!</ActionButton></div>
            </section>
            <Footer/>
        </main>
    );
}